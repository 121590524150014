import React from 'react'
import {
  MDBCarousel,
  MDBCarouselInner,
  MDBCarouselItem,
  MDBView,
  MDBMask,
} from 'mdbreact'
// import image1 from '../content/2019/cybercom/img/cybercom1.jpg'
import image2 from '../content/2019/cybercom/img/cybercom2.jpg'
import image3 from '../content/2019/cybercom/img/cybercom3.jpg'
import image4 from '../content/2019/cybercom/img/cybercom4.jpg'
// import image5 from '../content/2019/cybercom/img/cybercom5.jpg'
// import image6 from '../content/2019/cybercom/img/cybercom1.jpg'
// import image7 from '../content/2019/cybercom/img/cybercom2.jpg'
// import image8 from '../content/2019/cybercom/img/cybercom3.jpg'
// import image9 from '../content/2019/cybercom/img/cybercom4.jpg'
// import image10 from '../content/2019/cybercom/img/cybercom5.jpg'
// import image11 from '../content/2019/cybercom/img/cybercom1.jpg'
// import image12 from '../content/2019/cybercom/img/cybercom2.jpg'
// import image13 from '../content/2019/cybercom/img/cybercom3.jpg'
// import image14 from '../content/2019/cybercom/img/cybercom4.jpg'
// import image15 from '../content/2019/cybercom/img/cybercom5.jpg'
// import image16 from '../content/2019/cybercom/img/cybercom1.jpg'
// import image17 from '../content/2019/cybercom/img/cybercom2.jpg'
// import image18 from '../content/2019/cybercom/img/cybercom3.jpg'
// import image19 from '../content/2019/cybercom/img/cybercom4.jpg'
// import image20 from '../content/2019/cybercom/img/cybercom5.jpg'
// import image21 from '../content/2019/cybercom/img/cybercom1.jpg'
// import image22 from '../content/2019/cybercom/img/cybercom2.jpg'
// import image23 from '../content/2019/cybercom/img/cybercom3.jpg'
// import image24 from '../content/2019/cybercom/img/cybercom4.jpg'
// import image25 from '../content/2019/cybercom/img/cybercom5.jpg'

const CarouselPage = () => {
  return (
    <MDBCarousel activeItem={1} length={3} className="z-depth-1 w-100">
      <MDBCarouselInner>
        <MDBCarouselItem itemId="1">
          <MDBView>
            <img className="d-block w-100" src={image4} alt="First slide" />
            <MDBMask overlay="black-strong" />
          </MDBView>
        </MDBCarouselItem>
        <MDBCarouselItem itemId="2">
          <MDBView>
            <img className="d-block w-100" src={image2} alt="Second slide" />
            <MDBMask overlay="black-strong" />
          </MDBView>
        </MDBCarouselItem>
        <MDBCarouselItem itemId="3">
          <MDBView>
            <img className="d-block w-100" src={image3} alt="Third slide" />
            <MDBMask overlay="black-strong" />
          </MDBView>
        </MDBCarouselItem>
      </MDBCarouselInner>
    </MDBCarousel>
  )
}

export default CarouselPage
