import React from 'react'
import { MDBContainer, MDBBtn, MDBIcon, MDBRow, MDBCol } from 'mdbreact'

const Intro = ({ children }) => {
  return (
    <MDBContainer style={{ marginTop: '25vh', marginBottom: '40vh'}}>
      <MDBRow>
        <MDBCol md="12" className="white-text text-center">
          <h2 className="title h1-responsive font-weight-bold white-text mb-0 pt-md-5 pt-5">
            Årets Kontor - 2019
          </h2>
          <hr className="hr-light my-4 w-75" />
          <h4 className="subtext-header h4-responsive mt-2 mb-4">
            <p>Vi har utsett topp 25 snyggaste kontoren i Sverige.</p>
            <p>Bli inspirerad av fantastiska kontorsmiljöer.</p>
          </h4>
          <a href="#topp25">
            <MDBBtn color="info">
              <MDBIcon icon="fas fa-home" className="mr-2" />
              Visa topp-25
            </MDBBtn>
          </a>
        </MDBCol>
      </MDBRow>
    </MDBContainer>
  )
}

export default Intro
