import React from 'react'
import { MDBRow, MDBCol, MDBIcon, MDBContainer } from 'mdbreact'

const FeaturesPage = () => {
  return (
    <MDBContainer tag="section" className="text-center my-5">
      <h2 className="h1-responsive font-weight-bold my-5"><a id="topp25" />
        Topp 25 - Snyggaste Kontoren
      </h2>
      
{/*       <p className="lead grey-text w-responsive mx-auto mb-5">
        Det är inte helt lätt att avgöra vad som är ett snyggt kontor. Här är
        några av de faktorer som vi har tittat på när vi utsett Sveriges
        Snyggaste Kontor.
      </p> */}
      {/* <MDBRow>
        <MDBCol md="4">
          <MDBIcon fas icon="thumbs-up" size="3x" className="cyan-text" />
          <h5 className="font-weight-bold my-4">Finns det ett tema?</h5>
          <p className="grey-text mb-md-0 mb-5">
            Vi har tittat på om kontoren har
          </p>
        </MDBCol>
        <MDBCol md="4">
          <MDBIcon fas icon="thumbs-up" size="3x" className="cyan-text" />
          <h5 className="font-weight-bold my-4">Tutorials</h5>
          <p className="grey-text mb-md-0 mb-5">
            Lorem ipsum dolor sit amet, consectetur adipisicing elit.
            Reprehenderit maiores aperiam minima assumenda deleniti hic.
          </p>
        </MDBCol>
        <MDBCol md="4">
          <MDBIcon fas icon="thumbs-up" size="3x" className="cyan-text" />
          <h5 className="font-weight-bold my-4">Support</h5>
          <p className="grey-text mb-md-0 mb-5">
            Lorem ipsum dolor sit amet, consectetur adipisicing elit.
            Reprehenderit maiores aperiam minima assumenda deleniti hic.
          </p>
        </MDBCol>
      </MDBRow> */}
    </MDBContainer>
  )
}

export default FeaturesPage
