import React from 'react'
import { Link } from 'gatsby'
import Img from 'gatsby-image'
import {
  MDBBtn,
  MDBCard,
  MDBCardBody,
  MDBCardImage,
  MDBCardTitle,
  MDBCardText,
  MDBCol,
} from 'mdbreact'

const CardExample = ({ node, title, excerpt, slug, cover }) => {
  return (
    <MDBCol style={{ maxWidth: '20rem' }}>
      <Link to={slug}>
        <MDBCard>
          <MDBCardImage
            className="img-fluid"
            src={cover.childImageSharp.fluid.originalImg}
            alt=""
            waves
          />
          <MDBCardBody>
            <MDBCardTitle className="text-center mb-0 black-text">
              {title}
            </MDBCardTitle>
            {/*<MDBCardText>{excerpt}</MDBCardText>
          <MDBBtn href="#">Click</MDBBtn>*/}
          </MDBCardBody>
        </MDBCard>
      </Link>
    </MDBCol>
  )
}

export default CardExample
